{
  "name": "howden-login",
  "version": "v4.18.0-rc19",
  "title": "Howden Login",
  "languages": [
    "es",
    "en"
  ],
  "copyright": "Copyright 2015-2024 | Howden Iberia | Todos los derechos reservados",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port 4200",
    "start-dev": "ng serve --port 4300 -c develop",
    "start-pre": "ng serve --port 4300 -c staging",
    "start-pro": "ng serve --port 4300 -c production",
    "build": "ng build",
    "build-dev": "ng build -c develop",
    "build-pre": "ng build -c staging",
    "build-pro": "ng build -c production",
    "build-DevelopmentHowdenGroup": "ng build --configuration devHowdenGroup",
    "build-StagingHowdenGroup": "ng build --configuration preHowdenGroup",
    "build-ProductionHowdenGroup": "ng build --configuration proHowdenGroup",
    "build-DevelopmentHowdenGroupB2C": "ng build --configuration devHowdenGroupB2C",
    "build-StagingHowdenGroupB2C": "ng build --configuration preHowdenGroupB2C",
    "build-ProductionHowdenGroupB2C": "ng build --configuration proHowdenGroupB2C",
    "watch": "ng build --watch",
    "watch-dev": "ng build --watch --configuration develop",
    "watch-pre": "ng build --watch --configuration staging",
    "watch-pro": "ng build --watch --configuration production",
    "test": "ng test",
    "lint": "eslint ",
    "lint-and-fix": "eslint --fix",
    "i18n-extract": "ng extract-i18n --output-path=src/locale --progress=true"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.6",
    "@angular/cdk": "^18.2.6",
    "@angular/common": "^18.2.6",
    "@angular/compiler": "^18.2.6",
    "@angular/core": "^18.2.6",
    "@angular/forms": "^18.2.6",
    "@angular/material": "^18.2.6",
    "@angular/platform-browser": "^18.2.6",
    "@angular/platform-browser-dynamic": "^18.2.6",
    "@angular/router": "^18.2.6",
    "@azure/msal-angular": "^3.0.21",
    "@azure/msal-browser": "^3.18.0",
    "@howdeniberia/core-front": "^2.18.0-rc13",
    "moment": "^2.30.1",
    "ngx-cookie-service": "^18.0.0",
    "ngx-currency": "^18.0.0",
    "ngx-toastr": "~18.0.0",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.3",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.6",
    "@angular-devkit/core": "^18.2.6",
    "@angular-devkit/schematics": "^18.2.6",
    "@angular/cli": "^18.2.6",
    "@angular/compiler-cli": "^18.2.6",
    "@angular/localize": "^18.2.6",
    "@eslint/js": "^9.10.0",
    "@schematics/angular": "18.2.6",
    "@types/eslint__js": "^8.42.3",
    "@types/jasmine": "^5.1.4",
    "@types/node": "^20.14.9",
    "angular-eslint": "18.2.0",
    "eslint": "^9.9.0",
    "jasmine-core": "^5.1.2",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-firefox-launcher": "~2.1.2",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "typescript": "~5.5.2",
    "typescript-eslint": "^8.1.0"
  }
}